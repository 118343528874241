@import "../../es.less";

@footer-height: 36px;

html {
    height: 100vh;
}

body {
    margin-bottom: @footer-height;
    overflow-x: hidden;
    overflow-y: auto;
}

.footer {
    .es-font-5;

    position: fixed;
    bottom: 0;
    height: @footer-height;
    width: 100vw;
    text-align: center;
    background-color: @es-gray-base;
    padding-top: 8px;

    > a {
        padding: 0 8px;
    }
}

.footer-analytics-consent {
    bottom: @footer-height !important;
    background-color: @es-gray-primary;
}