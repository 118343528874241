@import "../../es.less";

.errorBox {
    border: 1px solid @es-error;
    margin: @es-form-segment-gap-height 0;
    padding: 1.5rem 3rem;
    color: @es-error;

    h1, h2, h3, h4, h5, h6 {
        color: @es-error;
    }
}