// Different shades of white
@es-white-1: #ffffff;
@es-white-2: #c1c6c9;
@es-white-3: #c1c6c9;
@es-white-4: #94989b;
@es-white-5: #7e8184;

// ES theme colours
@es-highlight: #59cbe8;
@es-highlight-muted: #3094a5;
@es-gray-base: #1a1a1c;
@es-gray-primary: #202123;
@es-gray-secondary: #2b2f33;
@es-gray-indent: #262a2d;

@es-success: #50cc6e;

// This is the colour that the Design team wanted:
//     @es-error: #EF2149;
// But it turns out to be a pain in the arse to change the bootstrap error colour,
// especially in ES2; so we're using theirs instead.
@es-error: #a94442;

// Used for size calculations
@es-logo-width: 285px;
@es-logo-height: 45px;
@es-form-segment-gap-height: 30px;

// Transition properties for changing colours e.g. on hover
@es-color-transition: 0.3s ease;

// The design team specify their font sizes in points; we translate
// these to pixels using this approximate conversion: http://reeddesign.co.uk/test/points-pixels.html
.es-font-size(@sizeInPoints, @sizeInRems) {
    font-size: @sizeInPoints;
    font-size: @sizeInRems;
}

.es-font-size-1 { .es-font-size(16pt, 2.0rem); }
.es-font-size-2 { .es-font-size(14pt, 1.8rem); }
.es-font-size-3 { .es-font-size(12pt, 1.6rem); }

.es-font(@fontSize, @fontColor) {
    @fontSize();
    color: @fontColor;
    font-weight: 400;
}

.es-font-1 { .es-font({ .es-font-size-1; }, @es-white-1); }
.es-font-2 { .es-font({ .es-font-size-1; }, @es-white-2); }
.es-font-3 { .es-font({ .es-font-size-2; }, @es-white-3); }
.es-font-4 { .es-font({ .es-font-size-2; }, @es-white-4); }
.es-font-5 { .es-font({ .es-font-size-3; }, @es-white-5); }


// Bootstrap breakpoints
@bootstrap-screen-sm-min: 768px;
@bootstrap-screen-md-min: 992px;
@bootstrap-screen-lg-min: 1200px;

@bootstrap-screen-xs-max: @bootstrap-screen-sm-min - 1;
@bootstrap-screen-sm-max: @bootstrap-screen-md-min - 1;
@bootstrap-screen-md-max: @bootstrap-screen-lg-min - 1;


// Target IE 10 & 11 specifically
// (see: https://stackoverflow.com/questions/28417056/how-to-target-only-ie-any-version-within-a-stylesheet)
.internet-explorer(@rules) {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { @rules(); }
}