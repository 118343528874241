@import "../../es.less";

.pageHeader {
    height: 122px;
    margin-top: 2rem;
    margin-bottom: @es-logo-height / 2;

    svg {
        display: block;
        width: @es-logo-width;
        height: 80px;
        margin: 0 auto 2rem;

        path[class='cls-1'], rect[class='cls-1'], circle[class='cls-1'] {
            fill: @es-white-1;
        }

        .internet-explorer({
            height: 66.16px;
        })
    }
}