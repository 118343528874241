@import "../../es.less";

.instructions {
    .es-font-5();
    text-align: center;
    margin: 0 auto @es-form-segment-gap-height;
}

.noInstructions {
    padding-top: 1rem;
}