@import "../../es.less";

// Reset font-sizes so 1rem === 10px by default. See: https://snook.ca/archives/html_and_css/font-size-with-rem
html { font-size: 62.5%; }

body {
    .es-font-5();
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    background-color: @es-gray-base !important;
}

label {
    font-weight: 400;
}

a {
    &:link, &:visited, &:hover, &:active {
        color: @es-white-2;
        text-decoration: none;

        transition: color @es-color-transition;
    }

    &:hover {
        color: @es-highlight;
    }
}

h1 { .es-font-1(); }
h2 { .es-font-2(); }
h3 { .es-font-3(); }
h4 { .es-font-4(); }
h5 { .es-font-5(); }

h1, h2, h3, h4, h5 {
    margin-top: 0;
}

.fade-enter {
    opacity: 0;
    z-index: 1;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 250ms ease-in;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 250ms ease-in;
}

.animated-page-enter {
    opacity: 0.1;
    transform: translateX(-1rem);
    z-index: 1;
}

.animated-page-enter.animated-page-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 250ms ease-in;
}

.animated-page-exit {
    opacity: 1;
}

.animated-page-exit.animated-exit-active {
    opacity: 1;
    transition: all 250ms ease-in;
}


//////////////////// FORMS

input {
    &[type='text'], &[type='password'], &[type='email'] {
        .es-font-3();

        border: 1px solid transparent;
        border-radius: 0;
        background-color: @es-gray-indent;
        padding: 0 2.5rem;
        line-height: 2rem;
        height: 4rem;

        &:focus {
            border: 1px solid @es-highlight;
        }

        &:focus, &.has-error {
            box-shadow: none;
        }

        &:disabled {
            color: @es-white-5;
            background-color: @es-gray-secondary;
        }
    }

    &[type='checkbox'] {
        position: absolute;
        width: 0;
        height: 0;
        color: @es-white-3;

        + label {
            position: absolute;
            background-color: @es-gray-indent;
            border: 1px solid transparent;
            padding: 0.9rem;
            display: inline-block;

            &:active {
            }

            + label {
                padding-left: 3.5rem;
                color: @es-white-3;
            }
        }

        &:checked + label {
            &:active {
            }

            &:after {
                content: '\2714';
                font-size: 1.3rem;
                position: absolute;
                top: 0;
                left: 4px;
                color: @es-white-3;
            }
        }

        &:focus + label {
            outline: 5px auto -webkit-focus-ring-color;
        }
    }
}

.btn:focus, .btn:active, btn:active:focus {
    outline: none !important;
    background-color: transparent !important;
}

.checkbox-wrapper {
    padding-top: 5px;

    label {
        margin-top: -5px;
    }
}

.form-group {
    margin-bottom: 2rem;

    &.has-error {
        span.help-block {
            padding-left: 2.6rem;
        }
    }
}

.sso-link-column {
    margin-bottom: 24px;
}