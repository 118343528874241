@import "../../../../es.less";

.change-password {
    .svg {
        svg {
            height: 3rem;
            vertical-align: middle;
            padding-bottom: 4px;
            width: 30px;

            // Cater for the extra padding around the arrow which throws off the
            // centering of the text
            margin-left: -1.6rem;

            polygon {
                fill: @es-highlight-muted;

                transition: fill @es-color-transition;
            }
        }
    }

    .svgColor(@color) {
        svg {
            path {
                fill: @color;
            }
        }
    }

    .svgPass {
        .svgColor(#679b38);
    }

    .svgFail {
        .svgColor(#ef2249);
    }

    .passwordCriteria {
        margin: @es-form-segment-gap-height auto;
    }
}