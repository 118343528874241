@import "../../es.less";

.actionButton {
    .es-font-size-2();

    display: block;
    border: 1px solid @es-highlight-muted;
    color: @es-highlight-muted;
    background-color: transparent;
    text-align: center;
    text-transform: uppercase;
    padding: 1.4rem 3rem 1.2rem;
    width: 24.5rem;
    margin: @es-form-segment-gap-height auto;

    transition:
        color @es-color-transition,
        border @es-color-transition;

    .arrow {
        svg {
            width: 4rem;
            height: 4rem;
            vertical-align: middle;
            padding-bottom: 4px;

            // Cater for the extra padding around the arrow which throws off the centering of the text
            margin-left: -1.6rem;

            polygon {
                fill: @es-highlight-muted;

                transition: fill @es-color-transition;
            }
        }
    }

    &:hover {
        cursor: pointer;
        color: @es-highlight;
        border-color: @es-highlight;

        .arrow {
            svg {
                polygon {
                    fill: @es-highlight;
                }
            }
        }
    }

    &:disabled {
        color: @es-gray-secondary;
        border-color: @es-gray-secondary;

        .arrow {
            svg {
                polygon {
                    fill: @es-gray-secondary;
                }
            }
        }
    }
}
